import Image from 'next/legacy/image';
import Link from 'next/link';
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  Icon,
  AspectRatio,
  useMediaQuery
} from '@chakra-ui/react';
import { analytics, rudderTrack } from 'pages';
import ArrowRightUp from 'remixicon-react/ArrowRightUpLineIcon';
import { containerGradient } from '@theme/foundations/colors';

const cards = [
  {
    header: 'Developer Experience',
    content: `We are a team of dev-tool experts, and know all the pain points involved with
    building for the web.  We’ve painstakingly built the authentication platform you’ve
    always wished you had — one that “just works”, and one that still keeps you in
    control of your user data and user experience.`,
    img: '/images/home/card-1.svg',
    imgSmall: '/images/home/card-1-sml.svg'
  },
  {
    header: 'Secure by Default',
    content: `We are a team of experienced web-security experts focused on helping
    you ship your product securely, without having to sweat over all the intricate security details.  All of our APIs are built following industry best-practices
    and are regularly audited by 3rd parties.`,
    img: '/images/home/card-2.svg',
    imgSmall: '/images/home/card-2-sml.svg'
  },
  {
    header: 'Dedicated Partners',
    content: `We know auth is core to your app, so we go above and beyond
    to make sure you’re well supported. There’s always someone on call
    to answer any questions you may have, or there in case of emergencies. 
    We offer industry leading SLAs and support agreements. `,
    img: '/images/home/card-3.svg',
    imgSmall: '/images/home/card-3-sml.svg'
  }
];

export function WhyClerk(): JSX.Element {
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

  return (
    <Box pos="relative" px={{ base: 6, lg: 0 }}>
      <Stack spacing={32} zIndex={1} pos="relative">
        <Box pos="relative" alignSelf="center">
          <Image
            src="/images/home/dark-logo.svg"
            alt=""
            height="56"
            width="56"
          />
          <Heading as="h4" size="md" color="primary.300" mb={2} mt={'40px'}>
            Why Clerk
          </Heading>

          <Heading as="h3" size="h3" color="white" mb={'48px'} maxW="784px">
            Created by engineers and founders, we built Clerk from the ground up
            to solve our own problem.
          </Heading>
          <Box display="flex" justifyContent={{ base: 'center', sm: 'left' }}>
            <Link legacyBehavior href="/company" passHref>
              <Button
                as="a"
                mb={16}
                bg="primary.500"
                w={{ base: 'full', sm: 'auto' }}
                rightIcon={<Icon as={ArrowRightUp} boxSize={5} />}
                variant="base"
                onClick={() => {
                  analytics.track(
                    'Marketing_Home_About our Company Button Clicked',
                    { surface: 'Marketing', location: 'Home' }
                  );
                  // rudderTrack(
                  //   'Marketing_Home_About our Company Button Clicked',
                  //   { surface: 'Marketing', location: 'Home' }
                  // );
                }}
              >
                About our company
              </Button>
            </Link>
          </Box>

          <Flex direction={{ base: 'column', lg: 'row' }} gap={8}>
            {cards.map((card, index) => (
              <Box
                key={index}
                maxW={{ lg: '680px' }}
                border="1px solid rgba(255, 255, 255, 0.1)"
                backdropBlur="24px"
                padding={6}
                borderRadius="12px"
                boxShadow="containerShadow"
                {...containerGradient}
              >
                <Box overflow="hidden" borderRadius="12px">
                  <AspectRatio
                    ratio={{ base: 279 / 120, lg: 632 / 134 }}
                    w="full"
                  >
                    <Image
                      src={isLargerThan600 ? card.img : card.imgSmall}
                      alt="background"
                      layout="fill"
                    />
                  </AspectRatio>
                </Box>

                <Heading
                  size="h5"
                  color="white"
                  paddingTop="32px"
                  fontSize="xl"
                >
                  {card.header}
                </Heading>

                <Text
                  textStyle="paragraph-md"
                  color="dark-text"
                  paddingTop="16px"
                >
                  {card.content}
                </Text>
              </Box>
            ))}
          </Flex>
        </Box>
      </Stack>
    </Box>
  );
}
